import Cookies from 'js-cookie'
import symbolMap from '@/utils/symbolMap'
import { loadLanguageAsync } from '@/locales'

const state = {
  lang: 'en-US',
  schoolCode: null,
  schoolName: null,
  appLoading: false,
  currencySymbol: '',
  systemList: [], // 有权限的系统列表
  newSystemList: [] // 产研特殊列表
}

const mutations = {
  SET_LANG: (state, lang) => {
    state.lang = lang
    localStorage.setItem('lang', lang)
  },
  SET_SCHOOL_CODE: (state, schoolCode) => {
    state.schoolCode = schoolCode
    localStorage.setItem('schoolCode', schoolCode)
    Cookies.set('schoolCode', schoolCode, {
      domain: '.thethinkacademy.com',
      sameSite: 'None',
      secure: true
    }) // 临时兼容方案
  },
  CHANGE_APP_LOADING: (state, status) => {
    state.appLoading = status
  },
  SET_CURRENCY_SYMBOL: (state, schoolCode) => {
    state.currencySymbol = symbolMap[schoolCode]
    localStorage.setItem('currencySymbol', symbolMap[schoolCode])
  },
  SET_SCHOOL_NAME: (state, name) => {
    state.schoolName = name
  },
  SET_SYSTEM_LIST: (state, systemList) => {
    state.systemList = systemList
  },
  SET_NEWSYSTEM_LIST: (state, newSystemList) => {
    state.newSystemList = newSystemList
  }
}

const actions = {
  setLang({ commit }, lang) {
    return new Promise((resolve, reject) => {
      commit('SET_LANG', lang)
      loadLanguageAsync(lang)
        .then(() => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  setSchool({ commit }, school) {
    commit('SET_SCHOOL_CODE', school.schoolCode)
    commit('SET_CURRENCY_SYMBOL', school.schoolCode)
    commit('SET_SCHOOL_NAME', school.name)
  },

  changeAppLoading({ commit }, status) {
    commit('CHANGE_APP_LOADING', status)
  },

  setSystemList({ commit }, list) {
    const systemList = list.map(item => {
      return item.frontendSymbol
    })
    const newSystemList = list.filter(item => item.systemCategory && item.systemCategory === 2)
    commit('SET_SYSTEM_LIST', systemList)
    commit('SET_NEWSYSTEM_LIST', newSystemList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
