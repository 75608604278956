import { queryProperWordsList } from '@/api/trans'

const state = {
  originLang: 1, // 原始语言 1: 中文, 2: 英文
  targetLang: 2, // 目标语言
  transType: 1, // 翻译类型 1: 题目翻译, 2: 文本翻译
  isTransIng: false, // 是否正在翻译
  properWordsList: [], // 专有名词列表 
  innerMatchWordsList: [], // 内部匹配词列表
  innerMatchSignsList: [], // 内部匹配符号列表
}

const mutations = {
  SET_PROPER_WORDS_LIST: (state, list) => {
    state.properWordsList = list
  },
  SET_TRANS_LANG: (state, {originLang , targetLang }) => {
    state.originLang = originLang
    state.targetLang = targetLang
  },
  SET_TRANS_TYPE: (state, transType) => {
    state.transType = transType
  },
  SET_INNER_MATCH_WORDS_LIST: (state, list) => {
    state.innerMatchWordsList = list
  },
  SET_INNER_MATCH_SIGNS_LIST: (state, list) => {
    state.innerMatchSignsList = list
  },
  SET_IS_TRANS_ING: (state, isTransIng) => {
    state.isTransIng = isTransIng
  }
}

const actions = {
  getProperWordsList({ commit }) {
    return new Promise((resolve, reject) => {
      queryProperWordsList().then(response => {
        const { data } = response
        commit('SET_PROPER_WORDS_LIST', data.dataList)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setTransLang({ commit }, info) {
    commit('SET_TRANS_LANG', info)
  },
  setTransType({ commit }, transType) {
    commit('SET_TRANS_TYPE', transType)
  },
  setInnerMatchWordsList({ commit }, list) {
    commit('SET_INNER_MATCH_WORDS_LIST', list)
  },
  setInnerMatchSignsList({ commit }, list) {
    commit('SET_INNER_MATCH_SIGNS_LIST', list)
  },
  setIsTransIng({ commit }, isTransIng) {
    commit('SET_IS_TRANS_ING', isTransIng)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}