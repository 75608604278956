import { getToken, setToken, removeToken } from '@/utils/auth'
import symbolMap from '@/utils/symbolMap'
import { login, getInfo, logout } from '@/api/user'
import { getSystemList } from '@/api/basic'
import store from '@/store'

import Cookies from 'js-cookie'
const config = {
  domain: '.thethinkacademy.com',
  sameSite: 'None',
  secure: true
}
const state = {
  token: getToken(),
  name: '',
  zhName: '',
  email: '',
  workCode: '',
  schoolList: [],
  schoolCode: '',
  schoolName: '',
  currencySymbol: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_USER_INFO: (state, userInfo) => {
    state.name = userInfo.enName || userInfo.zhName
    state.zhName = userInfo.zhName
    state.email = userInfo.email
    state.workCode = userInfo.empNo
    localStorage.setItem('userName', userInfo.zhName)
    Cookies.set('workCode', userInfo.empNo, config)
    Cookies.set('userName', userInfo.zhName, config)
  },

  SET_SCHOOL_LIST: (state, schoolCodeList) => {
    state.schoolList = schoolCodeList
    Cookies.set('schoolList', JSON.stringify(schoolCodeList), config)
  },

  SET_SCHOOL_CODE: (state, schoolCode) => {
    state.schoolCode = schoolCode
    // 临时兼容方案
    Cookies.set('schoolCode', schoolCode, config)
  },

  SET_SCHOOL_NAME: (state, schoolName) => {
    state.schoolName = schoolName
    Cookies.set('schoolName', schoolName, config)
  },

  SET_CURRENCY_SYMBOL: (state, schoolCode) => {
    state.currencySymbol = symbolMap[schoolCode]
    localStorage.setItem('currencySymbol', symbolMap[schoolCode])
    Cookies.set('currencySymbol', symbolMap[schoolCode], config)
  }
}

const actions = {
  login({ commit }, token) {
    return new Promise((resolve, reject) => {
      login({ ssoToken: token, type: 1 })
        .then(response => {
          const { data } = response

          commit('SET_TOKEN', data.accessToken)
          setToken(data.accessToken)

          commit('SET_SCHOOL_LIST', data.schoolCodeList)
          localStorage.setItem('schoolList', JSON.stringify(data.schoolCodeList))

          // 设置测试环境默认为火星
          const isProd = process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'pre'
          let defaultSchoolCode = data.schoolCodeList[0].schoolCode
          let defaultSchoolName = data.schoolCodeList[0].name

          if (!isProd) {
            defaultSchoolCode = '8601'
            defaultSchoolName = data.schoolCodeList.find(item => item.schoolCode === '8601')?.name
          }
          commit('SET_SCHOOL_CODE', defaultSchoolCode)
          localStorage.setItem('schoolCode', defaultSchoolCode)

          commit('SET_SCHOOL_NAME', defaultSchoolName)
          localStorage.setItem('schoolName', defaultSchoolName)

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  logout({ commit, state }) {
    return new Promise(resolve => {
      logout({ accessToken: state.token }).then(() => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    })
  },

  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo({ accessToken: getToken() })
        .then(async response => {
          const userInfo = response.data
          commit('SET_USER_INFO', userInfo)
          const res = await getSystemList()
          if (res && Array.isArray(res.data)) {
            store.dispatch('app/setSystemList', res.data)
          }
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      removeToken()
      resolve()
    })
  },

  setSchool({ commit }, [schoolCode, schoolName]) {
    commit('SET_SCHOOL_CODE', schoolCode)
    commit('SET_SCHOOL_NAME', schoolName)
    commit('SET_CURRENCY_SYMBOL', schoolCode)
  },

  setSchoolList({ commit }, schoolCodeList) {
    commit('SET_SCHOOL_LIST', schoolCodeList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
