<template>
  <div class="app-wrapper">
    <GlobalHeader />
    <div class="layout-container">
      <router-view />
    </div>
    <GlobalFooter />
  </div>
</template>

<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'BasicLayout',
  components: {
    GlobalHeader,
    GlobalFooter
  }
}
</script>

<style lang="stylus" scoped>
.app-wrapper
  display flex
  flex-direction column
  min-height 100%
  background #f7f7f7
.layout-container
  flex auto
  box-sizing border-box
  height calc(100% - 48px - 108px)
</style>
