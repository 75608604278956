<template>
  <div class="app-wrapper">
    <div class="layout-container">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'QiyueCustomerInfoLayout',
}
</script>

<style lang="stylus" scoped>
.app-wrapper
  height 100%
  background #f7f7f7
.layout-container
  box-sizing border-box
#subApp
  height 100%
</style>
