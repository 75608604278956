import router from './router'
import store from './store'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import subRegister from './micro/subRegister'
import { setDocumentTitle } from '@/utils/domUtil'

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`)

  console.log(to.meta)

  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/auth') {
      next('/')
    } else {
      const hasRoles = store.state.user.workCode
      if (hasRoles) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          subRegister() // 获取完用户信息后，再执行注册 qiankun 操作
          next({ ...to, replace: true })
        } catch (error) {
          await store.dispatch('user/resetToken')
          next('/')
        }
      }
    }
  } else {
    if ((to.query.token && to.path === '/auth') || to.path === '/403') {
      next()
    } else {
      window.location.href = process.env.VUE_APP_SSO_URL + '?referer=' + encodeURI(location.href)
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
