/**
 * Global authority directive
 * Used for fine-grained control of component permissions
 * @Example v-permission="RoleEnum.TEST"
 */

 import store from '@/store'

export function setupPermissionDirective(app) {
  app.directive('permission', (el, binding) => {
    const value = binding.value
    if (!value) return
    const systemList = store.state.app.systemList
    if (!systemList.includes(value)) {
      el.parentNode?.removeChild(el)
    }
  })
}
