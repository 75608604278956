import { createRouter, createWebHistory } from 'vue-router'
import MicroLayout from '@/layout/MicroLayout'
import BasicLayout from '@/layout/BasicLayout'
import ChatGPTLayout from '@/layout/ChatGPTLayout'
import QiyueCustomerInfoLayout from '@/layout/QiyueCustomerInfoLayout'
// import { getSystemList } from '@/api/basic'
// import store from '@/store'

const routes = [
  {
    path: '/',
    component: BasicLayout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: '/client',
        name: 'Client',
        component: () => import('@/views/client'),
        meta: {
          title: '客户端版本管理'
        }
      }
    ]
  },

  {
    path: '/marketing/:path(.*)*',
    name: 'Marketing',
    component: MicroLayout,
    meta: {
      title: '营销系统'
    }
  },

  {
    path: '/coach/:path(.*)*',
    name: 'Coach',
    component: MicroLayout,
    meta: {
      title: '辅导后台'
    }
  },

  {
    path: '/message/:path(.*)*',
    name: 'Message',
    component: MicroLayout,
    meta: {
      title: '消息系统'
    }
  },

  {
    path: '/integral/:path(.*)*',
    name: 'Integral',
    component: MicroLayout,
    meta: {
      title: '积分后台'
    }
  },

  {
    path: '/lego/:path(.*)*',
    name: 'Lego',
    component: MicroLayout,
    meta: {
      title: '商城运营后台'
    }
  },
  {
    path: '/crm/:path(.*)*',
    name: 'Crm',
    component: MicroLayout,
    meta: {
      title: 'crm管理后台'
    }
  },

  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth')
  },

  {
    path: '/chat',
    name: 'Chat',
    component: ChatGPTLayout,
    redirect: 'trans',
    meta: {
      title: 'Think AI Translator'
    },
    children: [
      {
        path: '/trans',
        name: 'Trans',
        component: () => import('@/views/trans')
      },
      {
        path: '/setting',
        name: 'Setting',
        component: () => import('@/views/trans/setting')
      }
    ]
  },
  {
    path: '/qiyue',
    name: 'Qiyue',
    component: QiyueCustomerInfoLayout,
    redirect: 'qiyue-customer-info',
    meta: {
      title: 'Qiyue Customer Info'
    },
    children: [
      {
        path: '/qiyue-customer-info',
        name: 'QiyueCustomerInfo',
        component: () => import('@/views/qiyueCustomerInfo')
      }
    ]
  },

  {
    path: '/403',
    name: '403',
    component: () => import('@/views/exception/403')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500')
  },

  {
    path: '/:path(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export function setupRouter(app) {
  // initSystemList()
  solveStyleLose()
  app.use(router)
}

// 初始化系统权限列表
// function initSystemList() {
//   router.beforeEach(async (to, from, next) => {

//     next()
//   })
// }

// 为了解决子项目刷新后主项目路由页面样式丢失问题的临时解决方案
function solveStyleLose() {
  const childRoute = ['/marketing', '/coach', '/message', '/integral', '/lego']
  const isChildRoute = path => childRoute.some(item => path.startsWith(item))
  const rawAppendChild = HTMLHeadElement.prototype.appendChild
  const rawAddEventListener = window.addEventListener
  router.beforeEach((to, from, next) => {
    // 从子项目跳转到主项目
    if (isChildRoute(from.path) && !isChildRoute(to.path)) {
      HTMLHeadElement.prototype.appendChild = rawAppendChild
      window.addEventListener = rawAddEventListener
    }
    next()
  })
}

export default router
