import {
  Avatar,
  Button,
  Col,
  Divider,
  Row,
  Spin,
  Result,
  Dropdown,
  Menu,
  Modal,
  Tooltip,
  ConfigProvider,
  Form,
  Input,
  Select,
  Tabs,
  Table,
  Radio,
  InputNumber,
  DatePicker,
  Badge,
  Tag,
  Upload,
  Popover,
  Slider,
  Drawer,
  Layout,
  Progress,
  Pagination
} from 'ant-design-vue'

export function setupAntd(app) {
  app.use(Avatar)
  app.use(Button)
  app.use(Col)
  app.use(Divider)
  app.use(Row)
  app.use(Spin)
  app.use(Result)
  app.use(Dropdown)
  app.use(Menu)
  app.use(Modal)
  app.use(Tooltip)
  app.use(ConfigProvider)
  app.use(Form)
  app.use(Input)
  app.use(Select)
  app.use(Tabs)
  app.use(Table)
  app.use(Radio)
  app.use(InputNumber)
  app.use(DatePicker)
  app.use(Badge)
  app.use(Tag)
  app.use(Upload)
  app.use(Popover)
  app.use(Slider)
  app.use(Drawer)
  app.use(Layout)
  app.use(Progress)
  app.use(Pagination)
}
