<template>
  <a-dropdown>
    <div class="glogle-header-right-item">
      <GlobalOutlined :style="{ fontSize: '14px' }" />
    </div>
    <template v-slot:overlay>
      <a-menu>
        <a-menu-item v-for="(item, index) in locales" :key="index" @click="changeLang(item)">
          <span class="lang-icon">{{ languageIcons[item] }}</span>
          {{ languageLabels[item] }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { GlobalOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    GlobalOutlined
  },
  setup() {
    const store = useStore()

    const state = reactive({
      locales: ['zh-CN', 'en-US'],
      languageLabels: {
        'zh-CN': '简体中文',
        'en-US': 'English'
      },
      languageIcons: {
        'zh-CN': '🇨🇳',
        'en-US': '🇺🇸'
      }
    })

    const changeLang = locale => {
      store.dispatch('app/setLang', locale)
      window.location.reload()
    }

    return {
      ...toRefs(state),
      changeLang
    }
  }
}
</script>

<style lang="stylus" scoped>
.lang-icon
  padding-right 4px
  vertical-align middle
</style>
