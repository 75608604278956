import request from '@/utils/request'
import Cookies from 'js-cookie'

// s3 获取 sessionToken
export function getS3Token(parameter) {
  console.log(process.env.VUE_APP_BASE_API_BIZ)
  return request({
    url: '/basic/backend/session/getToken',
    method: 'post',
    data: {
      header: {
        schoolCode: '8601'
      },
      data: parameter
    },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 获取系统权限列表
export function getSystemList() {
  return request({
    url: '/authority/biz/system/list',
    method: 'post',
    data: {
      header: {
        schoolCode: Cookies.get('schoolCode') || ''
      },
    },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}