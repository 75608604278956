import SvgIcon from './index.vue'
/**
 * 导入所有 SVG文件
 */
export const svgRequire=require.context('./icons',false,/\.svg$/);

/**
 * 全局注册SvgIcon
 */
// svgRequire.keys().forEach(svgIcon=>svgRequire(svgIcon));
svgRequire.keys().map(svgRequire)
export default (app)=>{
    app.component('svg-icon',SvgIcon);
}