export default {
  415: '$',
  4401: '￡',
  6501: 'S$',
  6502: 'S$',
  6503: 'S$',
  8601: 'S$',
  85201: 'HK$',
  103: 'CA$',
  6101: 'A$',
  6001: 'RM',
  8602: '$',
  105: '$',
  6506: 'S$',
  8101: '¥',
  8201: '₩',
  3301: '€'
}
