import request from '@/utils/request'

// 登录
export function login(parameter) {
  return request({
    url: '/authority/account/login',
    method: 'post',
    data: { data: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 获取用户信息
export function getInfo(parameter) {
  return request({
    url: '/authority/account/token/check',
    method: 'post',
    data: { header: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 登出
export function logout(parameter) {
  return request({
    url: '/authority/account/logout',
    method: 'post',
    data: { header: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 获取分校当地时间
export function getyCurrentTime(parameter) {
  return request({
    url: '/coupon/biz/template/queryCurrentTime',
    method: 'post',
    data: parameter,
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}
