const apps = [
  {
    name: 'sub_marketing',
    entry: process.env.VUE_APP_SUB_MARKETING,
    activeRule: '/marketing'
  },
  {
    name: 'sub_coach',
    entry: process.env.VUE_APP_SUB_COACH,
    activeRule: '/coach'
  },
  {
    name: 'sub_message',
    entry: process.env.VUE_APP_SUB_MESSAGE,
    activeRule: '/message'
  },
  {
    name: 'sub_integral',
    entry: process.env.VUE_APP_SUB_INTEGRAL,
    activeRule: '/integral'
  },
  {
    name: 'sub_lego',
    entry: process.env.VUE_APP_SUB_LEGO,
    activeRule: '/lego'
  }
  // {
  //   name: 'sub_crm',
  //   entry: process.env.VUE_APP_SUB_CRM,
  //   activeRule: '/crm'
  // }
]

export default apps
