<template>
  <div class="global-header">
    <div class="global-header-inner">
      <div class="header-left">
        <svg-icon class="icon-logo" icon-class="trans-logo" @click="toTranslate" />
      </div>
      <div class="header-right">
        <Avatar :translate="true" />
      </div>
    </div>
  </div>
  <div style="height: 76px" />
</template>

<script>
import Avatar from '../HeaderRight/Avatar'
import router from '@/router'

export default {
  components: {
    Avatar
  },
  setup() {
    const toTranslate = () => {
      router.push({ path: '/trans' })
    }
    return {
      toTranslate
    }
  }
}
</script>

<style lang="stylus" scoped>
.global-header
  position fixed
  top 0
  left 0
  width 100%
  z-index 100
.global-header-inner
  display flex
  justify-content space-between
  align-items center
  font-size: 16px
  padding 0 120px
  height 76px
  background #ffffff
.header-left
  display flex
  justify-content: center
  align-items center
  .header-text
    font-weight bold
    font-size: 25px;
    margin-left: 10px;
  .icon-logo
    width 278px
    height: 40px
    cursor pointer
  .title
    display inline-block
    font-size 20px
    font-weight bold
    padding-left 8px
    color #172B4D
.header-right
  display flex
  align-items center
  height 100%
  color rgba(0,0,0,0.65)
</style>
