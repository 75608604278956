<template>
  <div class="app-wrapper">
    <GlobalHeader />
    <div class="layout-container">
      <section id="subApp" />
    </div>
  </div>
</template>

<script>
import GlobalHeader from '@/components/GlobalHeader'

export default {
  name: 'MicroLayout',
  components: {
    GlobalHeader
  }
}
</script>

<style lang="stylus" scoped>
.app-wrapper
  height 100%
  background #f7f7f7
.layout-container
  box-sizing border-box
  height calc(100% - 48px)
#subApp
  height 100%
</style>
