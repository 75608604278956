<template>
  <div class="page-footer">
    <p>Copyright &copy; {{new Date().getFullYear()}} 海外产研组</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
.page-footer
  padding 48px 10px 24px
  color rgba(0, 0, 0, 0.3)
  text-align center
</style>
