export default {
  home: {
    businessSystem: 'Business System',
    businessSystemDesc: 'Class、 Order、 Payment and Receipt、 Student and Basic Data Management',
    messageSystem: 'Message System',
    messageSystemDesc: 'SMS、 E-mail Management、 Mobile Verification Code Log Inquiry',
    marketSystem: 'Marketing System',
    marketSystemDesc: 'Coupon、 Promotion',
    logisticsSystem: 'Logistics System',
    logisticsSystemDesc: 'Logistics System',
    tutorSystem: 'Tutoring Backend',
    tutorSystemDesc: 'Learning Materials, Classroom Data Management',
    serviceSystem: 'Service Desk',
    serviceSystemDesc: 'One-stop Service Desk for Teachers',
    mallOperationSystem: 'Mall Operation Backend',
    mallOperationSystemDesc: 'Official Website Operation, APP Mall Operation, Resource Management',
    permissionSystem: 'Permission System',
    permissionSystemDesc: 'Permission System',
    pointsSystem: 'Points Backend',
    pointsSystemDesc: 'Points Backend',
    resourceSystem: 'Resource Management',
    resourceSystemDesc: 'Resource Management',
    diagnoseSystem: 'Diagnostic Backend',
    diagnoseSystemDesc: 'Diagnostic Management, Score Management',
    growthSystem: 'Growth Tool Desk',
    growthSystemDesc: 'Enrollment Tools, Channel Management, Lead Management',
    tutoringToolSystem: 'Tutoring Tool Desk',
    tutoringToolSystemDesc: 'Tutoring & Service Integrated Backend',
    classroomLiveSystem: 'Classroom Live Broadcast Guarantee Management',
    classroomLiveSystemDesc: 'Teacher, Student-side Live Broadcast Monitoring',
    InClassSystem: 'In-class Operation Backend',
    InClassSystemDesc: 'Gift Configuration',
    teacherManageSystem: 'Teacher Management Platform',
    teacherManageSystemDesc: 'Sign-in Management',
    clientVersionSystem: 'Client Version Management',
    clientVersionSystemDesc: 'IOS, IPAD, Android, PC, MAC, Teacher-side',
    aiTranslaitonSystem: 'AI Translation',
    aiTranslaitonSystemDesc: 'AI Translation',
    practiceSystem: 'Practice-based Learning Management',
    practiceSystemDesc: 'Content Resource Management and Learning Engine'
  }
}
