<template>
  <div class="global-header">
    <div class="global-header-inner">
      <div class="header-left">
        <div v-show="$route.path === '/home'" class="title">{{ appTitle }}</div>
        <div v-show="$route.path !== '/home'" class="sub-area">
          <!-- <AppstoreOutlined class="icon-app" :style="{ fontSize: '18px' }" @click="toHome" />
          <RightOutlined class="icon-arrow" /> -->
          <div class="title sub-title">{{ $route.meta.title }}</div>
        </div>
      </div>
      <div class="header-right">
        <School />
        <Avatar />
        <Language />
      </div>
    </div>
  </div>
  <div style="height: 48px" />
</template>

<script>
// import { AppstoreOutlined, RightOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import Avatar from '../HeaderRight/Avatar'
import Language from '../HeaderRight/Language'
import School from '../HeaderRight/School'
import defaultSettings from '@/settings'
// import { ref } from 'vue'

export default {
  components: {
    // AppstoreOutlined,
    // RightOutlined,
    Avatar,
    Language,
    School
  },
  setup() {
    const router = useRouter()
    // const route = useRoute()

    const appTitle = defaultSettings.title

    const toHome = () => {
      router.replace({
        path: '/'
      })
    }

    return {
      appTitle,
      toHome
    }
  }
}
</script>

<style lang="stylus" scoped>
.global-header
  position fixed
  top 0
  left 0
  width 100%
  z-index 100
.global-header-inner
  display flex
  justify-content space-between
  align-items center
  padding 0 10px
  height 48px
  background #001529
  color #ffffff
.header-left
  margin-left 6px
  .title
    display inline-block
    font-size 20px
    font-weight bold
    padding-left 8px
  .sub-area
    display flex
    align-items center
    .sub-title
      font-size 18px
    .icon-app
      cursor pointer
    .icon-arrow
      padding 0 5px
.header-right
  display flex
  align-items center
  height 100%
</style>
