<template>
  <a-dropdown>
    <div class="glogle-header-right-item">
      <a-avatar :size="translate ? 32 : 22" style="background: #87d068; margin-right: 5px">
        <template v-slot:icon>
          <UserOutlined />
        </template>
      </a-avatar>
      <span>{{ userName }}</span>
    </div>
    <template v-slot:overlay>
      <a-menu :class="{ 'trans-menu': translate }">
        <a-menu-item v-if="translate" class="trans-menu-item" @click="toSetting">
          <span>
            <img class="icon-trans" width="14" src="@/assets/images/icon/trans-set.svg" />
            <img
              class="icon-trans-hover"
              width="14"
              src="@/assets/images/icon/trans-set-hover.svg"
            />
          </span>
          <span>设置</span>
        </a-menu-item>
        <a-menu-item @click="handleLogout" :class="{ 'trans-menu-item': translate }">
          <span>
            <img class="icon-trans" width="14" src="@/assets/images/icon/trans-logout.svg" />
            <img
              class="icon-trans-hover"
              width="14"
              src="@/assets/images/icon/trans-logout-hover.svg"
            />
          </span>
          <LogoutOutlined v-if="!translate" />
          <span>退出登录</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import router from '@/router'

export default {
  components: {
    UserOutlined,
    LogoutOutlined
  },
  props: {
    translate: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const userName = store.state.user.name

    const handleLogout = () => {
      const curRefer = route.path === '/trans' ? `?referer=${encodeURI(location.href)}` : ''
      Modal.confirm({
        title: '提示',
        content: '确定退出吗？',
        onOk: async () => {
          await store.dispatch('user/logout')
          window.location.href = `https://sso.zhiyinlou.com/sso/logout?path=${process.env.VUE_APP_SSO_URL}${curRefer}`
        }
      })
    }
    const toSetting = () => {
      router.push({ path: '/setting' })
    }
    return {
      userName,
      handleLogout,
      toSetting
    }
  }
}
</script>
