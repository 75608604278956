import { registerMicroApps, start } from 'qiankun'
import store from '@/store'
import NProgress from 'nprogress'

import app from '../main.js'
import microApps from './microApps'

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (app) {
    if (loading) {
      store.dispatch('app/changeAppLoading', true)
      NProgress.start()
    } else {
      store.dispatch('app/changeAppLoading', false)
      NProgress.done()
    }
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map(item => {
  return {
    ...item,
    container: '#subApp',
    props: {
      routerBase: item.activeRule
    },
    loader
  }
})

export default function subRegister() {
  registerMicroApps(apps, {
    beforeLoad: app => {
      console.log('before load app.name====>>>>>', app.name)
    },
    beforeMount: [
      app => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
      }
    ],
    afterMount: [
      app => {
        console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
      }
    ],
    afterUnmount: [
      app => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
      }
    ]
  })

  start({
    prefetch: false
  })
}
