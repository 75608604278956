<template>
  <!-- <a-config-provider :locale="locale"> -->
  <router-view />
  <AppLoading />
  <!-- </a-config-provider> -->
</template>

<script>
// import zhCN from 'ant-design-vue/es/locale/zh_CN'
import AppLoading from './components/AppLoading'

export default {
  components: {
    AppLoading
  },
  data() {
    return {
      // locale: zhCN
    }
  }
}
</script>
