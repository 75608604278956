import Cookies from 'js-cookie'

const TokenKey = 'adminAuthToken'
const config = {
  domain: '.thethinkacademy.com',
  sameSite: 'None',
  secure: true
}

export function getToken() {
  return Cookies.get(TokenKey, config)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, config)
}

export function removeToken() {
  return Cookies.remove(TokenKey, config)
}
