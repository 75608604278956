import request from '@/utils/request'

const prefiex = '/ai-translation/backend'
const api = {
  textTransLate: `${prefiex}/translation/translateContent`,
  queTransLate: `${prefiex}/translation/translateQuestion`,
  properWordsList: `${prefiex}/properNamesManagement/getAllProperWords`,
  faqsList: `${prefiex}/translation/listFAQs`,
  textProperWordsList: `${prefiex}/properNamesManagement/getAllProperWords`,
  properNameList: `${prefiex}/properNamesManagement/list`,
  properNameImport: `${prefiex}/properNamesManagement/import`,
  properNameDownload: `${prefiex}/properNamesManagement/getLatestFileUrl`,
  deleteAll: `${prefiex}/properNamesManagement/deleteAll`
}

// 文本翻译
export function queryTextTransLate(parameter) {
  return request({
    url: api.textTransLate,
    method: 'post',
    data: { data: parameter, header: {} },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 问题翻译
export function queryQueTransLate(parameter) {
  return request({
    url: api.queTransLate,
    method: 'post',
    data: { data: parameter, header: {} },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 获取专有名词列表
export function queryProperWordsList() {
  return request({
    url: api.properWordsList,
    method: 'post',
    data: {},
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 查询专有名词表格数据
export function queryProperNameList(parameter) {
  return request({
    url: api.properNameList,
    method: 'post',
    data: { data: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}
// 上传专有名词文件
export function wordsImport(parameter) {
  return request({
    url: api.properNameImport,
    method: 'post',
    data: { data: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}
// 下载专有名词文件
export function wordsDownload(parameter) {
  return request({
    url: api.properNameDownload,
    method: 'post',
    data: { data: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

export function queryFaqsList(parameter) {
  return request({
    url: api.faqsList,
    method: 'post',
    data: { data: parameter },
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}

// 下载专有名词文件
export function wordsListDelete() {
  return request({
    url: api.deleteAll,
    method: 'post',
    data: {},
    baseURL: process.env.VUE_APP_BASE_API_BIZ
  })
}
