import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Cookies from 'js-cookie'
import { notification } from 'ant-design-vue'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

service.interceptors.request.use(
  config => {
    if (store.state.user.token) {
      config.headers['X-Auth-Token'] = Cookies.get('adminAuthToken')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    // 特殊处理AI翻译提示
    const isAiTranslation = response.config.url.includes('ai-translation')
    if (res.code !== 0) {
      if (isAiTranslation) {
        return res
      } else {
        notification.error({
          message: 'Error',
          description: res.msg
        })
      }

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    notification.error({
      message: 'Error',
      description: error.response.data.msg || error.message
    })

    if (error.response.status === 403) {
      router.push('/403')
    }
    return Promise.reject(error)
  }
)

export default service
