import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { name, version } from '../../package.json'
import router from '../router'
import { initGlobalState, addGlobalUncaughtErrorHandler } from 'qiankun'

const initSentry = (app, release) => {
  const dsn = 'https://507a107132bc4731849c02517286ed3b@sentry.thethinkacademy.com/5'
  Sentry.init({
    app,
    dsn,
    release,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    trackComponents: true,
    tracesSampleRate: 1
  })
}

export function setupSentry(app) {
  const release = `${name}@${version}`
  initSentry(app, release)
  const actions = initGlobalState({ app, release })
  actions.onGlobalStateChange(state => {
    initSentry(state.app, state.release)
  })
}

addGlobalUncaughtErrorHandler(event => {
  Sentry.captureException(event)
})
