<template>
  <div class="app-loading" v-show="$store.state.app.appLoading">
    <a-spin size="large" />
  </div>
</template>

<style lang="stylus" scoped>
.app-loading
  position absolute
  top 40%
  left 50%
  transform translate(-50%, -50%)
</style>
