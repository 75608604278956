<template>
  <a-dropdown>
    <div class="glogle-header-right-item">
      <EnvironmentOutlined :style="{ fontSize: '14px' }" style="margin-right: 5px" />
      <span>{{ activeSchoolName }}</span>
    </div>
    <template v-slot:overlay>
      <a-menu>
        <a-menu-item
          v-for="(item, index) in schoolList"
          :key="index"
          @click="handleChangeSchool(item)"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { EnvironmentOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

export default {
  components: {
    EnvironmentOutlined
  },
  setup() {
    const store = useStore()

    const activeSchoolName = store.state.user.schoolName
    const schoolList = store.state.user.schoolList

    const handleChangeSchool = ({ schoolCode, name }) => {
      localStorage.setItem('schoolCode', schoolCode)
      localStorage.setItem('schoolName', name)
      store.dispatch('user/setSchool', [schoolCode, name])
      window.location.reload()
    }

    return {
      activeSchoolName,
      schoolList,
      handleChangeSchool
    }
  }
}
</script>
