import store from '@/store'
import Cookies from 'js-cookie'

// 初始化语言
store.dispatch('app/setLang', localStorage.getItem('lang') || 'en-US')

// 初始化分校
const schoolCode = Cookies.get('schoolCode')
const schoolName = Cookies.get('schoolName')
const schoolList = localStorage.getItem('schoolList')
if (schoolCode && schoolName && schoolList) {
  localStorage.setItem('schoolCode', schoolCode)
  localStorage.setItem('schoolName', schoolName)
  store.dispatch('user/setSchool', [schoolCode, schoolName])
  store.dispatch('user/setSchoolList', JSON.parse(schoolList))
}
