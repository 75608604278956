import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
import { setupStore } from './store'
import i18n from './locales'

import { setupAntd } from './core/lazy-use'
import { setupSentry } from './core/sentry'

// import SvgIcon from '@/components/SvgIcon/index.vue' 
import setupIcons from '@/components/SvgIcon'

import { setupPermissionDirective } from './directives/permission'
import sensors from 'sa-sdk-javascript'
console.debug(`https://shence-datasink.thethinkacademy.com/sa?${
    process?.env?.NODE_ENV === 'production'
      ? 'project=production&token=sa89427894'
      : 'project=default&token=sa3ff799b4'
  }`
)
sensors.init({
  server_url: `https://shence-datasink.thethinkacademy.com/sa?${
    process?.env?.NODE_ENV === 'production'
      ? 'project=production&token=sa89427894'
      : 'project=default&token=sa3ff799b4'
  }`,
  show_log: true,
  heatmap: {
    clickmao: 'default',
    scroll_notice_map: 'default'
  },
  is_track_single_page: true,
  use_client_time: true,
  send_type: 'beacon'
})
sensors.registerPage({
  platform_type: 'web',
  event_belong: 'micro-admin'
})
sensors.quick('autoTrack') // 开启全埋点
sensors.use('PageLeave', { heartbeat_interval_time: 5 }) // 开启页面浏览时长统计
sensors.use('PageLoad', {}) // 开启页面加载时长统计

import './core/bootstrap'
import './permission'
import './styles/index.styl'
import 'nprogress/nprogress.css'

const app = createApp(App)

app.use(i18n)

setupRouter(app)
setupStore(app)
setupAntd(app)
setupSentry(app)
setupPermissionDirective(app)
setupIcons(app)
app.config.globalProperties.$sensors = sensors

app.mount('#mainApp')

export default app
