import { createI18n } from 'vue-i18n'
import { createApp } from 'vue'
// import storage from 'store'
import moment from 'moment'
import App from '../App.vue'

// default lang
import enUS from './lang/en-US/index'

export const defaultLang = 'en-US'

const app = createApp(App)

const messages = {
  'en-US': {
    ...enUS
  }
}

const i18n = new createI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages
})
console.log(i18n, 'i18n');

app.use(i18n)
app.mount('#app')

const loadedLanguages = [defaultLang]

function setI18nLanguage(lang) {
  i18n.global.locale = lang
  // request.headers['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    // 缓存语言设置
    // storage.set('lang', lang)
    if (i18n.global.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}/index`).then(msg => {
          const locale = msg.default
          i18n.global.setLocaleMessage(lang, locale)
          loadedLanguages.push(lang)
          moment.updateLocale(locale.momentName, locale.momentLocale)
          return setI18nLanguage(lang)
        })
      }
      return resolve(setI18nLanguage(lang))
    }
    return resolve(lang)
  })
}

export function i18nRender(key) {
  return i18n.global.t(`${key}`)
}

export default i18n
