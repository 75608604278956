<template>
  <div class="app-wrapper">
    <ChatGPTHeader />
    <div class="layout-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import ChatGPTHeader from '@/components/ChatGPTHeader'

export default {
  name: 'MicroLayout',
  components: {
    ChatGPTHeader
  }
}
</script>

<style lang="stylus" scoped>
.app-wrapper
  height 100%
  background #f7f7f7
.layout-container
  box-sizing border-box
  height calc(100% - 76px)
#subApp
  height 100%
</style>
